/* ==========================================================================
  Main.js
========================================================================== */

(function($) {

  $(document).ready(function(){
    $('#site-navigation').lippukuntateema_navigation({
      desktop_min_width: 979, // min width in pixels
      menu_toggle: "#menu-toggle" // selector for toggle
    });
  });

  // $(document).ready(function(){
  //   $('body').fitVids();
  // });

  $(document).ready(function(){

  });

})(jQuery);
